@import '@/core/tamagoshi/scss';

.container {
  background-color: $lightGrey2Color;
  padding: $spacingLg $spacingMd;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    min-height: 100vh;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: $spacingLg;
}

.titleDesktop {
  display: none;
}

.illustration {
  width: 250px;
  height: 200px;
}

.subTitle {
  font-size: 16px;
  line-height: 24px;
  margin-top: $spacingLg;
  margin-bottom: $spacingXs;
}

.text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: $spacingLg;
  color: $n700;
}

.details {
  max-width: 328px;
}

.homeButton {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: $spacingSm $spacingMd;
  background-color: $ba500;
  border-radius: $defaultBorderRadius;
  font-weight: $fontWeightSemiBold;
  color: $n800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease-in;

  &:hover {
    background-color: $ba400;
  }
}

.homeButtonB2b {
  background-color: $v500;
}

@include size-above($mdWidth) {
  .container {
    padding-top: $spacingXXL1;
    padding-bottom: $spacingXXL3;
    gap: $spacingXXL3;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .title {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: $spacingXl;
  }

  .titleMobile {
    display: none;
  }

  .titleDesktop {
    display: inline;
  }

  .illustration {
    width: 300px;
    height: 250px;
  }

  .subTitle {
    font-size: 24px;
    line-height: 32px;
  }

  .text {
    margin-bottom: $spacingXl;
  }

  .details {
    max-width: 564px;
  }

  .homeButton {
    width: 328px;
    font-weight: $fontWeightSemiBold;
  }
}

@include size-above($lgWidth) {
  .container {
    padding-bottom: 144px;
  }
}
